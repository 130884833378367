<template>
  <div>
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="up-request" v-if="!loading">
      <h1 class="up-main-title">Заявка № {{ request.number }}</h1>
      <h2 class="up-request__name">{{ request.course.name }}</h2>
      <p class="up-request__paragraph">
        Создана:
        <time :datetime="request.created">
          {{ request.created | ruDate }}
        </time>
      </p>
      <p class="up-request__paragraph">
        Дата окончания:
        <time :datetime="request.dateUntil">
          {{ request.dateUntil | ruDate }}
        </time>
      </p>
      <p class="up-request__paragraph">
        Статус: <span>{{ statusTranslated }}</span>
      </p>
      <template v-if="request.listeners.length">
        <JsonExcel
          class="up-request__download-block"
          :data="request.listeners"
          :fields="json_fields"
          name="Слушатели.xls"
        >
          <el-button type="primary">Скачать</el-button>
        </JsonExcel>
        <AppBlockAsTable class="up-request__listener up-listener">
          <template slot="header">
            <p class="is-listener">Слушатель</p>
            <p class="is-email">Email</p>
            <p class="is-phone">Телефон</p>
            <p class="is-progress">Прогресс</p>
          </template>
          <div
            v-for="(l, i) in request.listeners"
            :key="i"
            class="up-table__item"
          >
            <p class="is-listener">
              <span class="is-pk-hidden">Слушатель:</span> {{ l.name }}
            </p>
            <p class="is-email">
              <span class="is-pk-hidden">Email:</span> {{ l.email }}
            </p>
            <p class="is-phone">
              <span class="is-pk-hidden">Телефон:</span> {{ l.phone }}
            </p>
            <p class="is-progress">
              <span class="is-pk-hidden">Прогресс:</span> {{ l.progressInfo }}
            </p>
            <p>
              <el-button
                type="text"
                icon="el-icon-zoom-in"
                @click="openDetailsModal(l)"
              >
                Детали
              </el-button>
            </p>
          </div>
        </AppBlockAsTable>
      </template>
      <p v-else class="up-empty-list-text">Список слушателей пуст!</p>
      <AppDetailsModal
        :visible.sync="detailsModalVisible"
        :listener-id="listenerInfo.id"
        :title="`${listenerInfo.name} - ${request.course.name}`"
      />
    </div>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppDetailsModal from "@/components/AppDetailsModal";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import JsonExcel from "vue-json-excel";

import requestStatuses from "@/enums/requestStatuses";

import { mapActions } from "vuex";

export default {
  name: "ShowRequest",
  components: { AppBlockAsTable, AppDetailsModal, JsonExcel, AppBreadcrumbs },
  data: () => ({
    request: {},
    detailsModalVisible: false,
    listenerInfo: {},
    loading: true,
    json_fields: {
      Слушатель: "name",
      Email: "email",
      Телефон: "phone",
      Прогресс: "progressInfo",
    },
    breadcrumbs: [
      {
        text: "Все заявки",
        path: "/customer/requests",
      },
      {
        text: "Просмотр заявки",
      },
    ],
  }),
  computed: {
    statusTranslated() {
      const s = requestStatuses.find(
        (s) => s.state === this.request.orderStatus
      );

      return s ? s.text : "";
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    openDetailsModal(listenerInfo) {
      this.detailsModalVisible = true;
      this.listenerInfo = listenerInfo;
    },
    async fetchRequestData() {
      this.$nprogress.start();

      try {
        const request = await this.$axios.get(
          `/customer/order/${this.$route.params.id}`
        );

        this.request = request.data;
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  async created() {
    await this.fetchRequestData();

    this.setNavigationText(`Страница заявки №${this.request.number}`);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/customer/requests/id.scss";
</style>