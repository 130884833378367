<template>
  <el-dialog
    center
    modal-centered
    custom-class="up-details-modal"
    v-bind="$attrs"
    v-on="$listeners"
    @open="fetchListenerInfo"
  >
    <AppBlockAsTable v-loading="loading">
      <div v-for="(l, i) in lessons" :key="i" class="up-table__item">
        <p class="up-details-modal__type">
          {{ i + 1 }}. {{ lessonTypeTranslated(l.lessonType) }}
        </p>
        <p class="up-details-modal__name">{{ l.name }}</p>
        <p v-if="l.dateDone" class="up-details-modal__icon">
          <i :class="iconClass(l)" />
        </p>
      </div>
    </AppBlockAsTable>
    <p
      v-if="lessons.length === 0"
      v-loading="loading"
      class="up-details-modal__text-not-found"
    >
      Данные не были получены!
      <el-button type="text" @click="fetchListenerInfo">
        Повторить попытку?
      </el-button>
    </p>
  </el-dialog>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";

export default {
  name: "AppDetailsModal",
  props: {
    listenerId: String,
  },
  data: () => ({
    lessons: [],
    loading: true,
  }),
  components: { AppBlockAsTable },
  methods: {
    lessonTypeTranslated(type) {
      return type === "lecture" ? "Лекция" : "Тест";
    },
    iconClass(lesson) {
      const iconType = lesson.state === "failed" ? "close" : "check";

      return `el-icon-circle-${iconType}`;
    },
    async fetchListenerInfo() {
      this.loading = true;

      const orderid = this.$route.params.id;

      try {
        const { data: res } = await this.$axios.get(
          `/customer/order/${orderid}/listener/${this.listenerId}`
        );

        this.lessons = res.steps;
      } catch (e) {
        if (!e.response.data) return;

        this.$message({
          message: e.response.data.errorMessage,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-details-modal.scss";
</style>